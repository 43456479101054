td{
    border: solid 2px #eee;
  }
  .clear{
    clear: both;
  }
  .active{
    color: #fff!important;
    background-color: #182d5e;
  }
  .hidden{
    display: none;
  }
  .synopsis-btn1{
    width: 45%;
    border:1px solid #182d5e;
    margin-left: 2.4%;
    margin-right:2.5%;
    text-align: center;
    float: left;
    color: #182d5e;
    padding: 2px;
    cursor: pointer;
  }
  .synopsis-btn2{
    width: 45%;
    border:1px solid #182d5e;
    margin-left: 2.4%;
    margin-right:2.5%;
    text-align: center;
    float: left;
    color: #182d5e;
    padding: 2px;
    cursor: pointer;
  }
  .padding-left-18{
    padding-left: 18px;
  }
  .text-center{
    text-align: center;
  }