ul{
    padding: 0px;
  }
  li{
    list-style: none;
  }
  .li-order{
    border-bottom: solid 1px #eee;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .order-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-header .store-name{
    background-color:#e3edfd;
    padding: 3px;
    color: #00235f;
  }
  .li-item{
    display: flex;
    padding: 9px 0px 9px;
  }
  .li-item.not-first{
    border-top: solid 1px #eee;
  }
  .item-image{
    max-width: 100px;
  }
  .item-image img{
    width: 100%;
  }
  .item-desc{
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
  }
  .item-name{
  }
  .item-quantity{
    float: right;
  }
  .item-price{
    font-weight: bold;
  }