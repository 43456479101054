*{
  /* font-family:  "Crimson Text","YuGothic", "Meiryo UI", "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",  "ＭＳ Ｐゴシッ ク", "MS PGothic", sans-serif !important; */
  font-family: monospace;
}
html{
  height: 100%;
}
body{
  height: 100%;
  min-height: 100vh;
}
.header{
text-align: center;
/* font-size: 15px; */
/* padding: 40px 0px 10px; */
/* margin-bottom: 15px; */
/* border-bottom: solid 1px #eee; */
background-color: white;
display: flex;
align-items: center;
justify-content: space-between;
height: 50px;
position: fixed;
width: 100%;
}
.header .members_logo{
/* padding: 10px 5px 5px 10px; */
}
.header .members_logo img{
/* max-width: 50%; */
max-height: 50px;
padding: 10px;
}
.header-close{
margin-top: -1px;
background-color: #ccc;
padding: 15px;
text-align: center;
border-top: 1px solid #00235E;
border-bottom: 2px solid #00235E !important;
}
.header-close span{
padding-left: 16px;
background: url("close.svg") left center/10px auto no-repeat;
}
.humbarger img{
/* margin-left: auto; */
width: 30px;
}
.main{
padding-top: 50px;
padding-bottom: 10px;
}
.corporateNavi{
position: fixed;
top: -100%;
left: 0;
width: 100%;
max-height: calc(100% - 50px);
overflow: auto;
-webkit-overflow-scrolling: touch;
background-color: #fff;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
opacity: 0;
-webkit-transform: translateY(30px);
transform: translateY(30px);
-webkit-transition: opacity .3s, top 0s .3s, -webkit-transform .3s;
transition: opacity .3s, top 0s .3s, -webkit-transform .3s;
transition: opacity .3s, transform .3s, top 0s .3s;
transition: opacity .3s, transform .3s, top 0s .3s, -webkit-transform .3s;
}
.corporateNavi.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 50px;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity .3s, top 0s, -webkit-transform .3s;
  transition: opacity .3s, top 0s, -webkit-transform .3s;
  transition: opacity .3s, transform .3s, top 0s;
  transition: opacity .3s, transform .3s, top 0s, -webkit-transform .3s;
}
.footer{
width: 100%;
padding: 0px;
/* background-color: #00235F;
color: white; */
background-color: white;
/* border: solid 2px #eee; */
}
.footer ul{
padding: 0px;
margin: 15px 0px 0px 0px;
}
ul{
margin: 0px;
padding: 0px;
width: 100%;
}
ul.submenu{
background-color: white;
}
ul.submenu li{
cursor: pointer;
list-style: none;
/* padding: 10px 0px 10px; */
/* margin: 0px; */
border-bottom: 1px solid #ADC2D6;
}
ul.submenu li a{
display: flex;
padding: 15px 20px 15px 35px;
background: url("arrow.svg") 18px center/4px auto no-repeat;
color: #002071;
/* color: #01235D; */
font-family: "Crimson Text","Yu Mincho",YuMincho,serif !important;
/* font-family: "Avenir","Helvetica Neue","Verdana",Helvetica,Arial,YuGothic,"Meiryo UI",メイリオ,Meiryo,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","MS PGothic",sans-serif !important; */
}
ul.submenu a:hover{
text-decoration: none;
}
.fdcp-btn{
cursor: pointer;
text-decoration: none;
padding: 1em;
color: white;
/* background-color: #00235F; */
background-color: white;
border: solid 1px black;
color: black;
display: inline-block;
text-align: center;
}
.fdcp-btn:hover {
color: white;
border: solid 1px white;
background-color: black;
text-decoration: none;
}
.fdcp-btn:disabled {
background-color: gray;
border: solid 1px white;
color: inherit;
cursor: not-allowed;
}
.btn-full{
width:100%;
}
.font-sm{font-size: 10px;}
.font-md{font-size: 12px;}
.font-lg{font-size: 15px;}
.font-xl{font-size: 20px;}
.annotation{color: gray;}
.notice{
color: black;
/* color: #00235F; */
}
.input-set{
margin-top: 20px;
margin-bottom: 20px;
}
.input-set label{
display: block;
}
.input-set input{
border-bottom: solid 1px #eee;
border-right:none;
border-left:none;
border-top:none;
width: 100%;
padding: 7px 7px 7px;
}
.input-set input.error{
border-bottom: solid 1px red;
}
.input-set select.error{
border-bottom: solid 1px red;
}
.input-set textarea.error{
border-bottom: solid 1px red;
}
.input-set .error-message{
color:red;
}
#barcode{
  margin: 10px auto 10px;
}
.barcode-wrapper{
text-align: center;
border: solid 1px #eee;
background-color: white;
/* box-shadow: 0px 0px 15px rgba(200,200,200,0.8); */
width: 100%;
padding: 40px 10px;
margin-bottom: 5px;
}
.ts-code-show{
margin: 10px 10px 0px;
}
.a-reset{
color: inherit;
text-decoration: none;
}
.a-reset:hover{
color: inherit;
text-decoration: none;
}
.padding-side{
padding: 0px 15px 0px;
}
.footer .mypage{
margin:20px;
text-align: center;
}
.footer .mypage .fdcp-btn{
padding: 0.5em;
width: 80%;
margin-left: auto;
margin-right: auto;
}
label {
  /* display: inline-block; */
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}