ul.ul-items{
    padding: 10px;
    background-color: #f7f8f8;
  }
  li.li-item{
    list-style: none;
  }
  .li-item{
    display: flex;
    padding: 15px 0px 15px;
  }
  .li-item.not-first{
    border-top: solid 1px #eee;
  }
  .input-set{
    margin:0px;
    width: 100%;
  }
  .input-set label{
    display: inline-block !important;
  }
  .input-set input{
    border: solid 1px #eee;
  
  }
  
  .select-set{
    display: inline-block;
    margin-right: 10px;
  }
  .select-set select{
    padding: 7px 7px 7px;
    background-color: white;
    height: 40px;
    min-width: 70px;
    border: 0;
    outline: 1px solid #ccc;
    outline-offset: -1px;
    margin-right: 10px;
  }
  
  
  .require{
    margin-left: 5px;
    padding: 0px 10px 0px;
    font-weight: normal;
    background-color: #eee;
  }
  .radio-set{
    display: flex;
  }
  .radio-set>label{
    line-height: 3em;
    margin-right: 20px;
  }
  .radio-set input{
    margin-right: 3px;
    width: auto;
  }
  #terms{
    margin-left: 7px;
    margin-right: 7px;
  }
  .toast-message{
    background: #000;
    color: #fff;
    margin: auto;
    width: 60vw;
    height: 50px;
    top: 150px;
    min-height: 15px;
    font-size: 15px;
  }