.members-txt{
    text-align: left;
    /* font-size: 10px; */
  }
  ul.ul-items{
    padding: 10px;
    background-color: #f7f8f8;
    text-align: left;
  }
  li.li-item{
    list-style: none;
  }
  .li-item{
    display: flex;
    padding: 15px 0px 15px;
  }
  .li-item.not-first{
    border-top: solid 1px #eee;
  }
  .input-set{
    margin:0px;
    width: 100%;
  }
  /* .input-set label{
    display: inline-block;
  } */
  .input-set input{
    border: solid 1px #eee;
  
  }
  
  .select-set{
    display: inline-block;
    margin-right: 10px;
  }
  .select-set select{
    padding: 7px 7px 7px;
    background-color: white;
    height: 40px;
    min-width: 70px;
    border: 0;
    outline: 1px solid #ccc;
    outline-offset: -1px;
    margin-right: 10px;
  }
  
  
  .require{
    margin-left: 5px;
    padding: 0px 10px 0px;
    font-weight: normal;
    background-color: #eee;
  }
  select.select-link{
    width:80%;
    text-align: center;
    margin: 0 auto;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 50px;
    padding: 0 25px 0 10px;
  }
  
  .free-dial{
    border: 1px solid black;
    text-align: center;
  }
  .telArea {
      text-align: center;
      border: 1px solid #000;
      padding: 15px;
  }
  .telArea .title {
    margin-bottom: 0;
    font-size: 0.85rem;
  }
  .telArea .tel {
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 5px;
  }
  .telArea .tel a {
    color: black;
  }
  .notice-title{
    font-size: 15px;
  }
  .special-notice{
    text-align: left;
    color: red;
  }
  .no-margin{
    margin: 0;
  }
  .color-red{
    color:red;
  }
  .text-left{
    text-align: left;
  }
  .new-year-notice{
    font-size: 12px;
    color: red;
    text-align: left;
  }
  .toast-message{
    background: #000;
    color: #fff;
    margin: auto;
    width: 60vw;
    height: 50px;
    top: 150px;
    min-height: 15px;
    font-size: 15px;
  }