.members-txt{
    /* text-align: left;
    font-size: 10px;
    padding: 0 10%; */
    font-size: 14px;
  }
  .benefits{
    width: 100%;
    /* height: 30px; */
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    background-color: #002071;
  }
  .benefits-brand{
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    /* border: solid 1px black; */
    /* margin-left: auto;
    margin-right: auto; */
  }
  .benefits-brand-span{
    /* width: 80%; */
    padding: 5px 10px;
    /* padding-bottom: 5px; */
    border: solid 1px black;
    text-align: center;
    /* margin-left: auto;
    margin-right: auto; */
  }
  .benefits-brand-text{
    width: 90%;
    padding: 1em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
  .indent{
    padding-left:2em;
    text-indent:-2em;
  }
  .indent-second{
    padding-left: 3em;
    text-indent: -1em;
  }
  .indent-min{
    padding-left:12px;
    text-indent: -12px;
    margin-bottom: 0;
  }
  
  .shop-btn{
    text-decoration: underline;
  }
  ul.shop-list{
    display: none;
  }
  ul.shop-list li a{
    color:black;
    height: 20px;
  }
  .shop-list.active{
    display: block;
  }
  
  select.select-link{
    margin: 0;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 26px;
    padding: 0 25px 0 10px;
  }
  .underline{
    color: #333;
    text-decoration:underline;
  }
  .underline:hover{
    color: #333;
  }
  .welfare{
    padding: 2% 30%;
    background: #f1f1f1;
  }
  .welfare-7{
    padding: 2% 65% 2% 2%;
    background: #f1f1f1;
  }
  .welfare-box{
    /* width: 80%; */
    padding-top: 5px;
    padding-bottom: 5px;
    /* border: solid 1px black; */
    /* margin-left: auto;
    margin-right: auto; */
  }
  .welfare-box-span{
    /* width: 80%; */
    padding: 5px 10px;
    /* padding-bottom: 5px; */
    border: solid 1px black;
    /* margin-left: auto;
    margin-right: auto; */
  }
  .no-border{
    border: none!important;
    outline: none!important;
  }
  .bottom-ten{
    position: relative;
    bottom: 10px;
  }
  .font-12{
    font-size: 12px;
  }
  .font-13{
    font-size: 13px;
  }
  .text-left{
    text-align: left;
  }
  .border-bottom{
    border-bottom: 1px solid black!important;
  }
  .padding-right-0{
    padding-right: 0!important;
  }
  .member-content{
    /* padding: 0 10%; */
  
    /* width:80%; */
    /* margin-left: auto;
    margin-right: auto; */
    padding: 0px 15px;
  }
  .object-box{
    padding: 3px 25px 3px 10px;
    border-bottom:1px solid black;
    height:26px;
    position: relative;
    top: 4px;
  }