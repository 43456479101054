.ul-items {
    padding: 10px;
    background-color: #f7f8f8;
  }
  
  .li-item {
    display: flex;
    padding: 9px 0px 9px;
  }
  
  .li-item.not-first {
    border-top: solid 1px #eee;
  }
  
  .input-set2 {
    margin: 0px;
  }
  
  .input-set2 input {
    border: none;
  }

  .input-set2 label {
   display: block !important;
  }
  
  .last_font {
    color: #8a9fa5;
    font-size: 12px;
    font-weight: normal;
  }
  