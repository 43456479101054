.home-content .padding-side{
  padding: 0% 4% 0%;
}
ul.menu{
  padding: 0px 2% 0px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
ul.menu a{
  display: block;
  width: 46%;
  border: solid 1px #eee;
  margin: 2%;
}
ul.menu li{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  list-style: none;
  font-weight: bold;
  height: 100px;
  width: 100%;
  position: relative;
  /* font-family: "Crimson Text","Yu Mincho",YuMincho,serif !important; */
}
ul.menu li span{
  z-index: -1;
  /* font-family: "Crimson Text","Yu Mincho",YuMincho,serif !important; */
}
ul.menu a:hover {
    text-decoration: none;
}
.color-red{
  color: red;
}
.special-font{
  font-family: "Crimson Text","Yu Mincho",YuMincho,serif !important;
}
.font-bold{
  font-weight: bold;
}