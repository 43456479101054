.question {
    font-weight: bold;
}

.under-line {
    text-decoration: underline;
}

.padding-box {
    padding-left: 16px;
    text-indent: -12px;
}

.padding-second-box {
    padding-left: 32px;
    text-indent: -32px;
}

hr {
    margin: 7px 0 !important;
    border-top: 1px solid #cdcdcd !important;
}