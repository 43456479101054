.member-wrapper .comment {
    margin-bottom: 50px;
  }
  
  ul.member-menu {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  ul.member-menu a {
    display: block;
    width: 48%;
    border: solid 1px #eee;
    margin: 2% 0 2% 0;
  }
  
  ul.member-menu .certificate {
    margin-right: 2%;
  }
  
  ul.member-menu .membersclub {
    margin-left: 2%;
  }
  
  ul.member-menu li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    list-style: none;
    font-weight: bold;
    height: 100px;
    width: 100%;
    position: relative;
  }
  
  ul.member-menu li span {
    z-index: -1;
  }
  
  ul.member-menu a:hover {
    text-decoration: none;
  }
  
  .color-red {
    color: red;
  }
  
  .special-font {
    font-family: "Crimson Text", "Yu Mincho", YuMincho, serif !important;
  }
  
  .font-bold {
    font-weight: bold;
  }
  