td {
    border: solid 2px #eee;
  }

  .return-btn {
    border: 1px solid black;
    background: #fff;
    padding: 5px 10px;
    /* border-radius: 5px; */
    outline: none;
    color: #000;
  }

  .padding-box {
    padding-left: 16px;
    text-indent: -12px;
    font-size: 14px;
  }
  .padding-second-box {
    padding-left: 27px;
    text-indent: -24px;
  }
  .padding-min-box {
    padding-left: 16px;
    text-indent: -14px;
  }
  .padding-left-14 {
    padding-left: 14px;
  }
  .padding-big-box{
    padding-left: 38px;
    text-indent: -35px;
  }