.user-avatar{
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.menu-list{
    font-size: 20px;
padding: 10px;
margin-left: 10px;
}
.home-ava{
border: 1px solid #ddd;
border-radius: 6px;
}
.home-ava>ul li{
font-weight: 200;
margin: 5px;
}

.cent{
list-style: none;
border-bottom:1px solid #DDD;
line-height: 5rem;
margin-left: -40px;
text-indent: 1.3rem;
}

.ts{
width:7rem;
height:7rem;
margin-top: 10px;
border-radius: 50%；
}
.ts-code{
width:3rem;
height:3rem;
margin-top: 30px;
/* border-radius: 50%； */
}
.ulsi{
background-color: white;
border-radius: 5px;
border: 1px solid #ddd;
list-style:none;
margin-top:2rem;
}
.avatm{
text-align:center;
height: 10rem;
}
.avatm img{
margin-left: -28px;
height: 7rem;
width: 7rem;
margin-top: 1rem;
}
.ols{
text-align: center;
}

.modal-dialog{
margin: 12vw !important;
}
.modal-title{
font-size: 13px !important;
}
.subbtn{
margin-right: 15px !important;
}
.guaranteecard-send{
margin:10px;
text-align: center;
}
.pull-right{
    float: right;
}